export interface Experiments {
  isWidgetAdditionalPaddingEnabled?: boolean;
  isWidgetPaddingImprovementEnabled?: boolean;
  isMultiParticipantStepEnabled?: boolean;
  isEnforceIndividualAsPremiumEnabled?: boolean;
  isMoreInfoLabelEnabled?: boolean;
  isFullWidthPaddingEnabled?: boolean;
  isCategoryLayoutImprovementMobileEnabled?: boolean;
  isCategoryLayoutImprovementDesktopEnabled?: boolean;
  isServiceListPageCategoryUpdateUrlEnabled?: boolean;
  isUseIntlToFormatCourseDurationEnabled?: boolean;
  isAccessibilityPricingEnabled?: boolean;
  isAccessibilityImprovementsEnabled?: boolean;
  isImageDimensionsCalcByParent?: boolean;
  isAccessibilityHeadingTagEnabled?: boolean;
  isUseIntlForPriceInBookFlowEnabled?: boolean;
}

export const experimentsSpecMap = {
  isImageDimensionsCalcByParent:
    'specs.bookings.WidgetImageDimensionsCalcByList',
  isWidgetAdditionalPaddingEnabled: 'specs.bookings.WidgetAdditionalPadding',
  isWidgetPaddingImprovementEnabled: 'specs.bookings.WidgetPaddingImprovement',
  isMultiParticipantStepEnabled: 'specs.bookings.MultiParticipantStep',
  isEnforceIndividualAsPremiumEnabled:
    'specs.bookings.EnforceIndividualAsPremium',
  isMoreInfoLabelEnabled: 'specs.bookings.MoreInfoLabel',
  isFullWidthPaddingEnabled: 'specs.bookings.WidgetFullWidthPadding',
  isCategoryLayoutImprovementMobileEnabled:
    'specs.bookings.CategoryLayoutImprovementMobile',
  isCategoryLayoutImprovementDesktopEnabled:
    'specs.bookings.CategoryLayoutImprovementDesktop',
  isServiceListPageCategoryUpdateUrlEnabled:
    'specs.bookings.ServiceListPageCategoryUpdateUrl',
  isUseIntlToFormatCourseDurationEnabled:
    'specs.bookings.UseIntlToFormatCourseDuration',
  isAccessibilityImprovementsEnabled:
    'specs.bookings.AccessibilityImprovements',
  isAccessibilityPricingEnabled: 'specs.bookings.AccessibilityPricing',
  isAccessibilityHeadingTagEnabled:
    'specs.bookings.headingTagAccessibilitySpec',
  isUseIntlForPriceInBookFlowEnabled:
    'specs.bookings.UseIntlForPriceInBookFlowSpecs',
};

function isExperimentsTrue(experimentValue) {
  return experimentValue === 'true' || experimentValue === 'new';
}

export function mapExperiments(
  experiments: any,
  defaultValue = false,
): Experiments {
  const mappedExperiments: Experiments = {};

  Object.entries(experimentsSpecMap).forEach((entry) => {
    const experimentEnablerName = entry[0];
    const experimentSpec = entry[1];
    mappedExperiments[experimentEnablerName] =
      experiments && experiments[experimentSpec]
        ? isExperimentsTrue(experiments[experimentSpec])
        : defaultValue;
  });

  return mappedExperiments;
}
